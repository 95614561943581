/* Dashboard.css */

.container {
    background-color: #f8f9fa; /* Color de fondo suave */
    border-radius: 8px;
    padding: 20px;
}

h1 {
    font-size: 2.5rem;
    color: #333;
}

.btn {
    font-size: 1.25rem;
}
