.navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
}

.nav-link {
    margin-right: 1rem;
}

.btn-outline-danger {
    margin-left: auto;
}
