.registro-avance-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.registro-avance-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.btn-primary {
    width: 100%;
}